<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">

        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">


            </div>
          </template>


          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Importar CSV" icon="pi pi-upload" class="p-button-success" @click="importarCsv()" />
          </template>
        </Toolbar>

        <div style="width: 100%; overflow: auto;">
          <h3>Padrão das colunas (Antes de Importar remover cabeçalho)</h3>
          <table class="tabela">
            <thead>
              <tr>
                <th title="Field #1">Unidade</th>
                <th title="Field #2">Rateio</th>

              </tr>
            </thead>
            <tbody></tbody>
          </table>


        </div>


        <Dialog v-model:visible="modalcsv" :style="{ width: '450px' }" :modal="true" header="Importar CSV - Separdo por ;">
          <div class="flex align-items-center justify-content-center">
            <div class="field">

              <FileUpload chooseLabel="Selecionar arquivo csv" ref="arquivo" mode="basic" name="arquivo"
                v-on:change="onChangeFileUpload()" />
            </div>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="modalcsv = false" />
            <Button label="Importar" icon="pi pi-check" class="p-button-success p-button-sm" @click="enviarCsv()" />
          </template>
        </Dialog>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalcsv: false,
      usinas: [],
      id_usina: "",
      ano: "",
      mes: "",
      anos: [
        { label: "", value: "" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },{ label: "2024", value: "2024" }],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      columns: null,
      energia: [],
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
      ],
      arquivo: "",
      mostrar_download: false,
      prioridade: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    };
  },
  productService: null,

  mounted() {

  },
  methods: {
    enviarCsv() {
      let formData = new FormData();
      formData.append("arquivo", this.arquivo);

      this.axios
        .post("/eum/rateio", formData)
        .then(() => {

          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Planilha Importada com sucesso",
            life: 3000,
          });
          this.modalcsv = false;
        })
        .catch(() => {

          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
    },

    importarCsv() {
      this.modalcsv = true;
    },


  },
};
</script>

<style scoped>
.tabela {
  margin: 20px 0;
}
.tabela th{
 white-space: nowrap;
 background: #EEE;
 padding: 5px;
}
</style>
